import React from "react";
import CallIcon from "../assets/images/icons/call-outline.svg";
import AddressIcon from "../assets/images/icons/address-outline.svg";
import MailIcon from "../assets/images/icons/mail-outline.svg";
import WhatsAppIcon from "../assets/images/icons/whatsapp-outline.svg";
import TwitterIcon from "../assets/images/icons/twitter.svg";
import Layout from "../components/layout";
import "../styles/pages/contact.scss";
export default function Home() {
  return (
    <Layout title="Contact">
      <h1 className="page-title">Contact</h1>
      <ul className="contacts-list">
        <li>
          <div>
            <img src={WhatsAppIcon} />
          </div>
          <div>
            <h3>WhatsApp Group</h3>
            <p>
              <a href="https://chat.whatsapp.com/GVuDN1ToQuU3oKq6wGm3hN">
                Join our <strong>NLRRWA - Semmancheri</strong> WhatsApp Group
                via this Invite Link
              </a>
            </p>
          </div>
        </li>
        <li>
          <div>
            <img src={AddressIcon} />
          </div>
          <div>
            <h3>Registered Address</h3>
            <p>
              SRINIKA, Plot No. 151B, Door No. 18, SRP Nagar, 1st Street Extn.,
              Semmancheri, Sholinganallur, Chennai - 600119.
            </p>
          </div>
        </li>
        <li>
          <div>
            <img src={MailIcon} />
          </div>
          <div>
            <h3>Email Address</h3>
            <p>
              <a href="mailto:nlrrwa2018@gmail.com">nlrrwa2018@gmail.com</a>
            </p>
          </div>
        </li>
        <li>
          <div>
            <img src={CallIcon} />
          </div>
          <div>
            <h3>Phone Number</h3>
            <p>
              <a href="tel:+919445954794">+91-94459-54794</a>
            </p>
          </div>
        </li>
        <li>
          <div>
            <img src={TwitterIcon} />
          </div>
          <div>
            <h3>Twitter</h3>
            <p>
              <a href="https://twitter.com/nlrrwa">twitter.com/nlrrwa</a>
            </p>
          </div>
        </li>
      </ul>
    </Layout>
  );
}
